import { memo } from 'react';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

function WelcomeToOCDJournal({ ...other }) {
    return (
        <Box {...other}>
            <svg width="320" height="204" viewBox="0 0 320 204" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="320" height="204" rx="20" fill="#0AACAC" />
                <g filter="url(#filter0_d_2391_3700)">
                    <rect x="107" y="54" width="90" height="96" rx="6" fill="white" />
                </g>
                <rect x="115" y="63" width="43" height="7" rx="3.5" fill="#5A574A" fill-opacity="0.2" />
                <rect x="115" y="81" width="73" height="7" rx="3.5" fill="#5A574A" fill-opacity="0.2" />
                <rect x="115" y="99" width="57" height="7" rx="3.5" fill="#5A574A" fill-opacity="0.2" />
                <rect x="115" y="117" width="73" height="7" rx="3.5" fill="#5A574A" fill-opacity="0.2" />
                <path d="M190.618 136.736L197.927 128.261L187.742 125.47L190.137 136.402L190.618 136.736Z" fill="#FFD9B4" />
                <path d="M187.738 125.469L197.923 128.26L206.856 93.8159L196.264 91.0984L187.738 125.469Z" fill="#FFC000" />
                <path d="M187.738 125.469L197.923 128.26L206.856 93.8159L196.264 91.0984L187.738 125.469Z" fill="#FFC000" />
                <rect width="2.22148" height="26.5098" rx="1.11074" transform="matrix(-0.969956 -0.243279 -0.243279 0.969956 198.945 96.5977)" fill="#FFD400" />
                <path d="M206.862 93.8176L207.31 91.6323L196.717 88.9148L196.27 91.1001L206.862 93.8176Z" fill="#D9D9D9" />
                <path d="M208.286 87.385L207.308 91.6322L196.716 88.9147L197.671 84.7645C197.923 83.6691 199.028 82.9966 200.117 83.2759L206.834 84.9991C207.885 85.2688 208.529 86.3275 208.286 87.385Z" fill="#F39393" />
                <defs>
                    <filter id="filter0_d_2391_3700" x="107" y="54" width="90.5" height="96.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dx="0.5" dy="0.5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.027451 0 0 0 0 0.196078 0 0 0 0 0.168627 0 0 0 0.3 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2391_3700" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2391_3700" result="shape" />
                    </filter>
                </defs>
            </svg>

        </Box>
    );
}

export default memo(WelcomeToOCDJournal);
