import React, { useEffect, useState, useMemo } from "react";
import { observer } from "mobx-react";
import {
    Typography,
    Box,
    styled,
    keyframes,
    Button,
} from "@mui/material";
import { pxToRem } from "../../theme/typography";
import useResponsive from "../../hooks/useResponsive";
import getStreakSummaryContent from "../../utils/streakSummaryContent";
import getStreakTitle from "../../utils/streakTitleContent";
import BaseModal from "../base-modal/BaseModal";
import { HEADER } from "../../config-global";
import MobileBottomBar from "../../layouts/app/bottom/MobileBottomBar";

/* ---------------------------------------------
   1) ANIMATION
   --------------------------------------------- */
const slideDown = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0.3;
  }
  60% {
    transform: translateY(10%);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

/* ---------------------------------------------
   2) STYLED COMPONENTS
   --------------------------------------------- */
const BubbleOldNumberText = styled(Typography)(
    ({ theme, $isActiveColor }) => ({
        fontWeight: 700,
        color: $isActiveColor ? "#FF8A00" : "#A5A4A2",
        WebkitTextStroke: $isActiveColor ? "5px #FFF9E2" : "5px #FFF9E2",
    })
);

const BubbleNumberText = styled(Typography)(
    ({ theme, $isActiveColor }) => ({
        animation: `${slideDown} 0.8s ease-out`,
        fontWeight: 700,
        color: $isActiveColor ? "#FF8A00" : "#A5A4A2",
        WebkitTextStroke: $isActiveColor ? "5px #FFF9E2" : "5px #FFF9E2",
    })
);

const BubbleCaptionText = styled(Typography)(
    ({ theme, $isActiveColor }) => ({
        fontWeight: 900,
        color: $isActiveColor ? "#FF8A00" : "#A5A4A2",
        WebkitTextStroke: $isActiveColor ? "2.3px #FFF9E2" : "2.3px #FFF9E2",
    })
);

/* ---------------------------------------------
   3) MAIN COMPONENT
   --------------------------------------------- */
const StreakSuccess = observer(({ open, handleClose, user, defaultTitle = "", defaultDescription = "", }) => {
    const [oldStreak, setOldStreak] = useState(user?.userStreak?.current_streak || "0");
    const [currentStreak, setCurrentStreak] = useState(null);

    const isDesktop = useResponsive("up", "lg");

    // Memoize title and description to prevent unnecessary recalculations
    const streakHeadline = useMemo(() => (defaultTitle || getStreakTitle(+oldStreak + 1)), [oldStreak]);
    const descriptionText = useMemo(() => (defaultDescription || getStreakSummaryContent(+oldStreak + 1)), [oldStreak]);

    // final color after streak updates to current
    const isActiveColor = !!currentStreak;

    const backgroundColor = isActiveColor ? "#FFB966" : "#D5D5D5";
    const fireIconSrc = isActiveColor
        ? "/assets/icons/streak/fireHighlightColor.svg"
        : "/assets/icons/streak/fireHighlight.svg";

    useEffect(() => {
        if (open) {
            setOldStreak(user?.userStreak?.current_streak || "0");

            setTimeout(() => {
                setCurrentStreak(oldStreak ? +oldStreak + 1 : 1);
            }, 1000);
        }

        return () => {
            setOldStreak(user?.userStreak?.current_streak || "0");
            setCurrentStreak(null);
        };
    }, [open]);

    return (
        <BaseModal
            open={open}
            onClose={handleClose}
            title={streakHeadline}
            description={descriptionText}
            noMarginHeader={true}
            getImageComponent={
                !isDesktop
                    ? () => (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                mt: isDesktop
                                    ? `${pxToRem(16)} !important`
                                    : `${pxToRem(HEADER.H_MOBILE)} !important`,
                            }}
                            data-qa-id="gumdrop-streak-container"
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    height: pxToRem(300),
                                    backgroundColor,
                                    borderRadius: pxToRem(16),
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: pxToRem(16),
                                    }}
                                >
                                    {!currentStreak && oldStreak && (
                                        <BubbleOldNumberText
                                            variant="h2"
                                            sx={{
                                                fontSize: pxToRem(104),
                                                lineHeight: 1,
                                            }}
                                            $isActiveColor={false}
                                        >
                                            {oldStreak || "0"}
                                        </BubbleOldNumberText>
                                    )}
                                    {currentStreak && (
                                        <BubbleNumberText
                                            variant="h2"
                                            sx={{
                                                fontSize: pxToRem(104),
                                                lineHeight: 1,
                                            }}
                                            $isActiveColor={true}
                                        >
                                            {currentStreak}
                                        </BubbleNumberText>
                                    )}

                                    <Box
                                        component="img"
                                        src={fireIconSrc}
                                        alt="Streak Fire"
                                        sx={{
                                            width: 96,
                                            height: 96,
                                        }}
                                    />
                                </Box>

                                <BubbleCaptionText
                                    variant="h5"
                                    sx={{ mt: 1, fontSize: pxToRem(34), lineHeight: 1 }}
                                    $isActiveColor={isActiveColor}
                                >
                                    day streak
                                </BubbleCaptionText>
                            </Box>
                        </Box>
                    )
                    : undefined
            }
            fullScreen={!isDesktop}
        >
            {/* Desktop version uses the big card inline */}
            {isDesktop && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        mt: pxToRem(16),
                    }}
                    data-qa-id="gumdrop-streak-container"
                >
                    <Box
                        sx={{
                            width: "100%",
                            height: pxToRem(300),
                            backgroundColor,
                            borderRadius: pxToRem(16),
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: pxToRem(16),
                            }}
                        >
                            {!currentStreak && oldStreak && (
                                <BubbleOldNumberText
                                    variant="h2"
                                    sx={{
                                        fontSize: `${pxToRem(104)} !important`,
                                        lineHeight: 1,
                                    }}
                                    $isActiveColor={false}
                                >
                                    {oldStreak}
                                </BubbleOldNumberText>
                            )}
                            {currentStreak && (
                                <BubbleNumberText
                                    variant="h2"
                                    sx={{
                                        fontSize: `${pxToRem(104)} !important`,
                                        lineHeight: 1,
                                    }}
                                    $isActiveColor={true}
                                >
                                    {currentStreak}
                                </BubbleNumberText>
                            )}

                            <Box
                                component="img"
                                src={fireIconSrc}
                                alt="Streak Fire"
                                sx={{
                                    width: 96,
                                    height: 96,
                                }}
                            />
                        </Box>

                        <BubbleCaptionText
                            variant="h5"
                            sx={{ mt: 1, fontSize: `${pxToRem(34)} !important`, lineHeight: 1 }}
                            $isActiveColor={isActiveColor}
                        >
                            day streak
                        </BubbleCaptionText>
                    </Box>
                </Box>
            )}

            {/* continue button */}
            {isDesktop ? (
                <Box
                    sx={{
                        mt: pxToRem(24),
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        variant="contained"
                        fullWidth
                        size="medium"
                        onClick={handleClose}
                        sx={{
                            maxWidth: pxToRem(400),
                        }}
                    >
                        Continue
                    </Button>
                </Box>
            ) : (
                <MobileBottomBar>
                    <Button
                        fullWidth
                        variant="contained"
                        size="medium"
                        onClick={handleClose}
                    >
                        Continue
                    </Button>
                </MobileBottomBar>
            )}
        </BaseModal>
    );
});

export default StreakSuccess;
