import { Suspense, lazy } from 'react';
import LoadingScreen from '../components/loading-screen/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
(
  <Suspense>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

// AUTH
export const AuthEntryPage = Loadable(lazy(() => import('../pages/auth/AuthEntryPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const AdminPage = Loadable(lazy(() => import('../pages/admin/AdminPage')));

// HOME
export const HomePage = Loadable(lazy(() => import('../pages/home/HomePage')));

// LANDING
export const LandingPage = Loadable(lazy(() => import('../pages/landing/LandingPage')));

// WIKI
export const WikiPage = Loadable(lazy(() => import('../pages/wiki/WikiPage')));

// CONVERSATIONS
export const ConversationsPage = Loadable(lazy(() => import('../pages/conversations/ConversationsPage')));

// PROFILE
export const ProfilePage = Loadable(lazy(() => import('../pages/profile/ProfilePage')));

// ACCOUNT
export const AccountPage = Loadable(lazy(() => import('../pages/account/AccountPage')));

// Error Pages
export const Page403 = Loadable(lazy(() => import('../pages/error-pages/Page403')));
export const NoAccessProfilePage = Loadable(lazy(() => import('../pages/error-pages/NoAccessProfilePage')));
export const NoMatchFoundPage = Loadable(lazy(() => import('../pages/error-pages/NoMatchFoundPage')));