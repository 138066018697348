import React from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { observer } from "mobx-react";
import { useStoresContext } from '../../stores/useStoresContext';
import getOnboardingTourSteps from './constants/onboardingTourSteps';
import useResponsive from '../../hooks/useResponsive';
import { pxToRem } from '../../theme/typography';

const OnboardingTour = observer(() => {
    const { layoutStore, questionStore } = useStoresContext();
    const isDesktop = useResponsive("up", "lg");

    const steps = getOnboardingTourSteps({
        layoutStore,
        selectedQuestionId: questionStore?.selectedQuestion?.question_id,
        isDesktop,
    });

    const handleJoyrideCallback = (data) => {
        const { status } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            layoutStore.closeTour();
        }
    };

    return (
        <Joyride
            steps={steps}
            run={layoutStore.isTourOpen}
            stepIndex={layoutStore.tourStepIndex}
            continuous={false}
            scrollToFirstStep
            showSkipButton={false}
            showProgress={true}
            callback={handleJoyrideCallback}
            disableOverlayClose={true}
            disableCloseOnEsc={true}
            disableScrolling={false}
            styles={{
                options: {
                    zIndex: 10000,
                    arrowColor: '#FFEFB7',
                    backgroundColor: '#FFEFB7',
                    textColor: '#000000',
                    ...(isDesktop && layoutStore.tourStepIndex !== 4 && layoutStore.tourStepIndex !== 5 && { width: "100%" }), // Only apply width when isDesktop is true
                },
                buttonBack: { display: 'none' },
                buttonSkip: { display: 'none' },
                buttonClose: { display: 'none' },
                buttonNext: { display: 'none' },
                tooltip: {
                    padding: "0",
                },
                tooltipContent: {
                    padding: "0", // We set the parent container in OnboardingTourSteps.js
                },
               
            }}
            locale={{
                back: 'Back',
                close: 'Close',
                last: 'Finish',
                next: 'Continue',
                skip: 'Skip',
            }}
        />
    );
});

export default OnboardingTour;
