// NavItem.js

import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { observer } from "mobx-react";
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Tooltip, ListItemText, Link, useTheme } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
//
import Iconify from '../../iconify';
import { StyledItem, StyledIcon, StyledBadge } from './styles';
import { useStoresContext } from '../../../stores/useStoresContext';

// ----------------------------------------------------------------------

const NavItemComponent =
  (
    {
      item,
      depth,
      open,
      active,
      unreadMessageCount = 0,
      isExternalLink,
      ...other
    },
    ref
  ) => {
    const theme = useTheme();
    const { translate } = useLocales();

    const { isReady: storesAreReady, questionStore, layoutStore } = useStoresContext();

    const { title, path, icon, info, children, disabled, caption } = item;

    const showUnreadMessages = title === 'conversations';

    const subItem = depth !== 1;

    // Active styles to be applied to icon and text
    const activeStyles = {
      color: theme.palette.primary.main, // Customize the color as needed
    };

    let isDisabled = disabled;

    if (title === "today") {
      if (storesAreReady) {
        if (questionStore.selectedQuestion === questionStore.activeQuestion) {
          isDisabled = true;
        }
      }
    }

    const renderContent = (
      <StyledItem
        ref={ref}
        open={open}
        depth={depth}
        onClick={() => {
          if (title === "today" && storesAreReady) {
            // jump to today's question
            questionStore.jumpToActiveQuestion();

            // Check if tour is on third step and tour is open
            if (layoutStore.isTourOpen && layoutStore.tourStepIndex === 2) {
              layoutStore.goToNextStep();
            }
          }
        }}
        // need className for onboarding tour
        className={`horizontal-nav-item-container-${title}`}
        data-qa-id={`horizontal-nav-item-container-${title}`}
        disabled={isDisabled}
        {...other}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 0,
          }}
        >
          {icon &&
            (showUnreadMessages ? (
              <StyledBadge
                badgeContent={unreadMessageCount}
                color="error"
                data-qa-id={'unread-badge-count'}
              >
                <StyledIcon sx={active ? activeStyles : {}}>
                  {icon}
                </StyledIcon>
              </StyledBadge>
            ) : (
              <StyledIcon sx={active ? activeStyles : {}}>
                {icon}
              </StyledIcon>
            ))}

          <ListItemText
            primary={`${translate(title)}`}
            primaryTypographyProps={{
              noWrap: true,
              component: 'span',
              variant: 'caption', // Adjust the variant as needed
              sx: {
                mt: 0.5,
                textAlign: 'center',
                lineHeight: 1.2,
                ...(active && activeStyles),
              },
            }}
          />
        </Box>

        {info && (
          <Box component="span" sx={{ mt: 1, lineHeight: 0 }}>
            {info}
          </Box>
        )}

        {caption && (
          <Tooltip title={`${translate(caption)}`} arrow>
            <Box component="span" sx={{ mt: 0.5, lineHeight: 0 }}>
              <Iconify icon="eva:info-outline" width={16} />
            </Box>
          </Tooltip>
        )}

        {!!children && (
          <Iconify
            icon={subItem ? 'eva:chevron-right-fill' : 'eva:chevron-down-fill'}
            width={16}
            sx={{ mt: 0.5, flexShrink: 0 }}
          />
        )}
      </StyledItem>
    );

    const renderItem = () => {
      // ExternalLink
      if (isExternalLink)
        return (
          <Link href={path} target="_blank" rel="noopener" underline="none">
            {renderContent}
          </Link>
        );

      // Default
      return (
        <Link component={RouterLink} to={path} underline="none">
          {renderContent}
        </Link>
      );
    };

    return renderItem();
  };

NavItemComponent.propTypes = {
  open: PropTypes.bool,
  active: PropTypes.bool,
  item: PropTypes.object,
  depth: PropTypes.number,
  isExternalLink: PropTypes.bool,
  unreadMessageCount: PropTypes.number,
};

// Combine observer and forwardRef
const NavItem = observer(forwardRef(NavItemComponent));

export default NavItem;
