// utils/streakTitleContent.js

// A dictionary of short motivational messages keyed by the streak day,
// intentionally avoiding the day number in the phrases.
const streakTitleMessages = {
    1: "Starting strong!",
    2: "Feeling that momentum!",
    3: "You’re getting the hang of this!",
    4: "Momentum is building!",
    5: "So far, so good!",
    6: "Look at you go!",
    7: "A whole new vibe!",
    8: "Watch the progress keep rolling!",
    9: "Still going strong!",
    10: "Confidence in motion!",
    11: "Routine is taking hold!",
    12: "Commitment in action!",
    13: "Boundless energy!",
    14: "Full of promise!",
    15: "High-energy day!",
    16: "Cruising ahead!",
    17: "Crushing those goals!",
    18: "Look how far you’ve come!",
    19: "You’re shining bright!",
    20: "Keep on pushing forward!",
    21: "Dedication is paying off!",
    22: "Steady as you go!",
    23: "No signs of stopping!",
    24: "Confidence looks good on you!",
    25: "Riding the wave of success!",
    26: "Digging deeper each day!",
    27: "Finding your groove!",
    28: "Hitting that steady stride!",
    29: "You’re unstoppable!",
    30: "It’s officially a habit now!",
    31: "All about consistency!",
    32: "Making real strides!",
    33: "Grit is your middle name!",
    34: "Feel the forward motion!",
    35: "Own that unstoppable vibe!",
    36: "One foot after another!",
    37: "Keep that motivation alive!",
    38: "Full steam ahead!",
    39: "Excellence on repeat!",
    40: "Now it’s second nature!",
    41: "Victory is in sight!",
    42: "Is there anything you can’t do?",
    43: "Persistence pays off!",
    44: "Confidence levels rising!",
    45: "Keep showing up!",
    46: "No stopping you now!",
    47: "Excellence is a habit!",
    48: "Look at this unstoppable pace!",
    49: "Ramping up that momentum!",
    50: "You’re on a roll!"
};

/**
 * Returns a short, snappy phrase to display for the current streak.
 * No explicit mention of the day count is included in these messages.
 * 
 * @param {number} currentStreak - The user's current streak count
 * @returns {string} A short motivational message without a numeric mention
 */
export default function getStreakTitle(currentStreak) {
    if (currentStreak <= 0) {
        return "Let’s begin the streak!";
    }

    if (currentStreak > 50) {
        // If they exceed the dictionary range:
        return "Champion mode: keep it going!";
    }

    // Fallback if the day count isn't in the map or any edge case:
    return streakTitleMessages[currentStreak] || "Another day, another milestone!";
}
