import { useState, useContext } from 'react';
// form
import { useForm } from 'react-hook-form';
// @mui
import { Stack, Button, Typography, Alert, Link, ListItemText, ListItemButton, List, Drawer, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// context
import { useAuthContext } from '../../../../../auth/useAuthContext';
// components
import FormProvider, { RHFIndividualCharactersField } from '../../../../../components/hook-form';
// styles
import { StyledRoot, StyledContent } from './styles';
// hooks
import useResponsive from '../../../../../hooks/useResponsive';
// constants
import { EXPIRED, WRONG_CODE } from '../../../../../constants/otp-verification-errors';
// context
import { AuthEntryContext } from '../../AuthEntryContext';
import { TimelineDot } from '@mui/lab';
import { pxToRem } from '../../../../../theme/typography';
import { useStoresContext } from '../../../../../stores/useStoresContext';
import { handleError } from '../../../../../utils/handleError';
import { CustomHeader } from '../../../../../components/custom-header';
import { NEW_USER, RETURNING_USER } from '../../../../../constants/unauthenticated-user-reasons';
import { MobileHeader } from "../../../../../layouts/app/header";
import MobileBottomBar from "../../../../../layouts/app/bottom/MobileBottomBar";
import { HEADER } from '../../../../../config-global';

// ----------------------------------------------------------------------
const filename = "/sections/auth/auth-entry/forms/otp-verification-form/OTPVerificationForm.js";

const SPACING = 8;

export default function OTPVerificationForm() {
    const theme = useTheme();
    const { layoutStore } = useStoresContext();
    const { state, previousStep, updateFields } = useContext(AuthEntryContext);

    const [editMenuVisible, setEditMenuVisible] = useState(false);

    const { verifyOTP, login, sendOTP } = useAuthContext();

    const [verificationFailed, setVerificationFailed] = useState(false);
    const [otpExpired, setOTPExpired] = useState(false);

    const isDesktop = useResponsive('up', 'lg');

    const methods = useForm({
        mode: "onChange",
    });

    const {
        reset,
        handleSubmit,
        formState: { isValid },
    } = methods;

    const handleOpenEditMenu = () => {
        setEditMenuVisible(true);
    };

    const handleCloseEditMenu = () => {
        setEditMenuVisible(false);
    };

    const handleRegisterModalOpen = (phone) => {
        layoutStore.setPhoneForRegistration(phone);
        layoutStore.openAuthModal({ flowType: "register" });
    };

    const handleRegisterModalClose = () => {
        layoutStore.closeAuthModal();
    };

    const onSubmit = async (data) => {
        let errorReason;

        try {
            setVerificationFailed(false);
            setOTPExpired(false);

            console.log("submitting otp")

            const { verification, user, reason } = await verifyOTP({ phone: state.phone, hash: state.hash, otp: data.otp });

            console.log({ verification, user, reason })
            errorReason = reason;
            if (verification) {
                if (user) {
                    // existing user, so change isUserNew state
                    layoutStore.setIsUserNew(RETURNING_USER);

                    await login();

                    handleRegisterModalClose();
                } else {
                    handleRegisterModalOpen(state.phone);
                }
            } else if (reason === WRONG_CODE) {
                setVerificationFailed(true);
                reset();
            } else if (reason === EXPIRED) {
                setOTPExpired(true);
                reset();
            }
        } catch (error) {
            handleError({
                error: error,
                message: `Error verifying OTP with reason: ${errorReason}`,
                filename,
                functionName: "onSubmit",
            });

            reset();
        }
    };

    const resendCode = async () => {
        try {
            const { hash } = await sendOTP(state.phone);

            reset();

            if (hash) {
                updateFields({ phone: state.phone, hash }, true);
            }
        } catch (error) {
            handleError({
                error: error,
                message: `Error resending OTP code to phone`,
                filename,
                functionName: "resendCode",
            });
        }
    };

    const handleClose = () => {
        layoutStore.closeAuthModal();
    };

    const customHeaderTitleNode = (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
            <Typography variant={isDesktop ? "caption" : "body1"} sx={{ textAlign: 'left', margin: `${pxToRem(12)} 0` }}>
                {layoutStore.isUserNew === NEW_USER ? "CREATE ACCOUNT" : "SIGN IN"}
            </Typography>
            <Typography variant="h4" sx={{ display: 'flex', alignItems: 'flex-end' }}>
                Enter your verification code
            </Typography>
        </Box>
    )

    return (
        <StyledRoot isDesktop={isDesktop} data-qa-id="otp-root-container">
            {isDesktop ? (
                <CustomHeader
                    titleNode={customHeaderTitleNode}
                />

            ) : (
                <MobileHeader
                    onClose={handleClose}
                    closeIconKey="mingcute:close-fill"
                />
            )}
            <StyledContent isDesktop={isDesktop} data-qa-id="otp-styled-content-container">
                <Stack spacing={isDesktop ? 4 : 2} sx={{ mt: isDesktop ? 2 : 0, mb: 4, width: "100%", paddingTop: isDesktop ? "0" : pxToRem(HEADER.H_MOBILE + SPACING), }} data-qa-id="otp-content-container">
                    {!isDesktop && customHeaderTitleNode}

                    <FormProvider
                        methods={methods}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Stack direction={"column"} justifyContent={"center"} spacing={2} sx={{ width: 1, margin: isDesktop ? "auto" : "inherit" }} data-qa-id="otp-form-container">
                            {verificationFailed && (
                                <Alert severity="error" data-qa-id="otp-form-wrong-code">Wrong code. Try again.</Alert>
                            )}

                            <Stack
                                component="span"
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-start"
                                mb={2}
                                data-qa-id="otp-form-edit-phone-container"
                            >
                                <Typography variant="h6" sx={{ color: 'text.secondary', mr: 1, fontSize: `${pxToRem(16)} !important` }} data-qa-id="otp-form-phone-text">
                                    Sent to {state.phone || "6465555555"}
                                </Typography>

                                <TimelineDot sx={{ padding: '0.1rem', alignSelf: 'center' }} />

                                <Link
                                    onClick={previousStep}
                                    sx={{ typography: 'button', ml: 1, fontSize: pxToRem(16), cursor: 'pointer', color: theme.palette.button.containedBackground, }} data-qa-id="otp-form-edit-phone-button"
                                >
                                    Edit
                                </Link>
                            </Stack>

                            <RHFIndividualCharactersField
                                name="otp"
                                autoFocus={true}
                                isDesktop={isDesktop} // Pass the isDesktop prop
                                numberKeyboardActive={!isDesktop} // tel keyboard if mobile
                            />

                            <Link
                                onClick={handleOpenEditMenu}
                                sx={{ typography: 'button', textTransform: 'none', fontSize: pxToRem(16), paddingTop: '1rem', width: 'fit-content', cursor: 'pointer', color: theme.palette.button.containedBackground, }}
                                data-qa-id="otp-form-did-not-get-code"
                            >
                                Didn't get a code?
                            </Link>

                            {isDesktop ? (
                                <Button
                                    fullWidth
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    disabled={!isValid}
                                    data-qa-id="otp-next-button"
                                >
                                    Continue
                                </Button>
                            ) : (
                                <MobileBottomBar>
                                    <Button
                                        fullWidth
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        disabled={!isValid}
                                        data-qa-id="otp-next-button"
                                    >
                                        Continue
                                    </Button>
                                </MobileBottomBar>
                            )}

                            {otpExpired && (
                                <Alert
                                    severity="error"
                                    onClick={() => {
                                        resendCode();
                                        setOTPExpired(false);
                                    }} data-qa-id="otp-form-code-expired">
                                    Verification code expired. <span style={{ textDecoration: "underline" }}>
                                        Click to resend.
                                    </span>
                                </Alert>
                            )}

                        </Stack>
                    </FormProvider>

                    <Drawer
                        anchor={'bottom'}
                        open={editMenuVisible}
                        onClose={handleCloseEditMenu}
                        sx={{ zIndex: `${theme.zIndex.modal + 1}` }} // Ensure Drawer appears above the Dialog
                        data-qa-id={"resend-container"}
                    >
                        <List>
                            <ListItemButton onClick={() => {
                                resendCode();
                                handleCloseEditMenu();
                            }}>
                                <ListItemText
                                    primary={"Send again"}
                                    primaryTypographyProps={{
                                        textAlign: 'center',
                                        fontSize: pxToRem(16),
                                        padding: "1rem 0",
                                        fontWeight: theme.typography.subtitle1.fontWeight
                                    }}
                                />
                            </ListItemButton>

                            <ListItemButton onClick={previousStep}>
                                <ListItemText
                                    primary={"Edit number"}
                                    primaryTypographyProps={{
                                        textAlign: 'center',
                                        fontSize: pxToRem(16),
                                        padding: "1rem 0",
                                        fontWeight: theme.typography.subtitle1.fontWeight
                                    }} />
                            </ListItemButton>
                        </List>
                    </Drawer>
                </Stack>
            </StyledContent>
        </StyledRoot>
    );
}
