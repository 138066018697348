import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// routes
import { PATH_APP } from '../routes/paths';
// components
import LoadingScreen from '../components/loading-screen/LoadingScreen';
// context
import { useAuthContext } from './useAuthContext';
import { useState } from 'react';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuthContext();
  const [loadingComplete, setLoadingComplete] = useState(false);

  const handleLoadingComplete = () => {
    setLoadingComplete(true);
  };

  if (isAuthenticated) {
    return <Navigate to={PATH_APP.root} />;
  }

  if (!isInitialized || !loadingComplete) {
    return <LoadingScreen onLoadingComplete={handleLoadingComplete} />
  }

  return <> {children} </>;
}
