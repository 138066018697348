import { makeObservable, observable, action } from "mobx"
import { NEW_USER } from "../../constants/unauthenticated-user-reasons";

class LayoutStore {
    shouldHideAppHeader = false;
    shouldHideHorizontalNav = false;
    isAuthModalOpen = false;
    modalContent = 'auth';
    isUserNew = '';
    registrationPhone = '';

    // handle day/month view
    dateView = 'day';

    isTourOpen = false; // Controls if the tour is running
    tourStepIndex = 0; // Current step index

    constructor() {
        makeObservable(this, {
            shouldHideAppHeader: observable,
            shouldHideHorizontalNav: observable,
            isAuthModalOpen: observable,
            modalContent: observable,
            isUserNew: observable,
            registrationPhone: observable,
            dateView: observable,
            reset: action,
            hideAppHeader: action,
            hideHorizontalNav: action,
            showAppHeader: action,
            showHorizontalNav: action,
            openAuthModal: action,
            closeAuthModal: action,
            setIsUserNew: action,
            setPhoneForRegistration: action,
            handleToggleDateView: action,

            isTourOpen: observable,
            tourStepIndex: observable,
            startTutorial: action,
            closeTour: action,
            goToNextStep: action,
            goToPrevStep: action,
            goToStep: action,
        });
    }

    reset = () => {
        this.shouldHideAppHeader = false;
        this.shouldHideHorizontalNav = false;
        this.isAuthModalOpen = false;
        this.modalContent = 'auth';
        this.registrationPhone = '';
        this.dateView = 'day'
    }

    // New actions to manage the onboarding tour
    startTutorial = () => {
        this.isTourOpen = true;
        this.tourStepIndex = 0;
    };

    closeTour = () => {
        this.isTourOpen = false;
        this.tourStepIndex = 0;
        // reset isUserNew so onboarding tour isn't re-triggered
        this.setIsUserNew('');
    };

    goToNextStep = () => {
        this.tourStepIndex += 1;
    };

    goToPrevStep = () => {
        if (this.tourStepIndex > 0) {
            this.tourStepIndex -= 1;
        }
    };

    goToStep = (step) => {
        this.tourStepIndex = step;
    };

    handleToggleDateView = () => {
        if (this.dateView === "day") {
            this.dateView = "month";
        } else {
            this.dateView = "day";
        }
    }

    hideAppHeader = () => {
        this.shouldHideAppHeader = true;
    }

    hideHorizontalNav = () => {
        this.shouldHideHorizontalNav = true;
    }

    showAppHeader = () => {
        this.shouldHideAppHeader = false;
    }

    showHorizontalNav = () => {
        this.shouldHideHorizontalNav = false;
    }

    openAuthModal = ({ flowType = "auth", isUserNew = NEW_USER }) => {
        this.isAuthModalOpen = true;
        this.modalContent = flowType;
        this.setIsUserNew(isUserNew);
    }

    closeAuthModal = (modalContentToReset = "auth") => {
        this.isAuthModalOpen = false;
        this.modalContent = modalContentToReset;
    }

    setIsUserNew = (isUserNew = NEW_USER) => {
        this.isUserNew = isUserNew;
    }

    setPhoneForRegistration = (phone) => {
        this.registrationPhone = phone;
    }
}

const layoutStore = new LayoutStore();

export default layoutStore;
