import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, Button, IconButton, AppBar } from '@mui/material';
import { useStoresContext } from '../../../stores/useStoresContext';
import PropTypes from 'prop-types';
import { useSprings, animated } from '@react-spring/web';
import { useTransition } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import debounce from 'lodash.debounce';
import useResponsive from '../../../hooks/useResponsive';
import { pxToRem } from '../../../theme/typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { handleEvent } from '../../../utils/handleEvent';
import { COMPLETED_COPING_STRATEGY_PREMATURELY, FINISHED_REPEAT_COPING_STATEMENT_STEP } from '../../../constants/analytics-events';
import { HEADER } from '../../../config-global';
import { styled } from '@mui/material/styles';

const RepeatCopingStatementStep = observer(({ onEnd }) => {
    const { copingStatementStore } = useStoresContext();
    const copingStatements = copingStatementStore.getCopingStatements();

    const isDesktop = useResponsive('up', 'lg');

    const [currentIndex, setCurrentIndex] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);

    // State variables for instruction text
    const [instructionText, setInstructionText] = useState('(try saying this aloud)');
    const [showInstructionText, setShowInstructionText] = useState(true);

    // Analytics tracking variables
    const startTimeRef = useRef(null);
    const eventSentRef = useRef(false); // To prevent multiple event sends
    const viewedStatementsRef = useRef(new Set([currentIndex])); // Track unique statements viewed
    const choseDifferentRef = useRef(false); // Indicates if user viewed more than one statement

    // Set the start time when the component mounts
    useEffect(() => {
        startTimeRef.current = Date.now();

        return () => {
            // Send the event if the component unmounts unexpectedly
            sendAnalyticsEvent();
        };
    }, []);

    // Function to send the analytics event
    const sendAnalyticsEvent = () => {
        if (eventSentRef.current) {
            return; // Event has already been sent, do nothing
        }
        eventSentRef.current = true; // Mark the event as sent

        const endTimestamp = Date.now();
        const durationMs = endTimestamp - startTimeRef.current;
        const durationSeconds = durationMs / 1000;

        const copingStatementsViewed = viewedStatementsRef.current.size;
        const choseDifferentCopingStatement = choseDifferentRef.current;

        handleEvent({
            eventName: FINISHED_REPEAT_COPING_STATEMENT_STEP,
            isDesktop,
            properties: {
                duration_seconds: durationSeconds,
                chose_different_coping_statement: choseDifferentCopingStatement,
                num_coping_statements_viewed: copingStatementsViewed,
            },
        });
    };

    // Handle window resize
    useEffect(() => {
        const handleResize = debounce(() => setWidth(window.innerWidth), 100);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            handleResize.cancel();
        };
    }, []);

    // Update instruction text after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            // Start fade-out of current instruction text
            setShowInstructionText(false);

            // After fade-out, change the instruction text and fade it in
            const fadeInTimer = setTimeout(() => {
                setInstructionText(
                    isDesktop
                        ? 'use arrows for a different coping statement'
                        : 'swipe left for a different coping statement'
                );
                setShowInstructionText(true);
            }, 500); // Duration matches the fade-out duration

            // Cleanup for fadeInTimer
            return () => clearTimeout(fadeInTimer);
        }, 5000);

        return () => clearTimeout(timer); // Clean up the timer on unmount
    }, [isDesktop]);

    // Create transition for instruction text
    const transitions = useTransition(showInstructionText, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 500 }, // Duration of the opacity transition
    });

    // Create springs for each coping statement
    const [springs, api] = useSprings(copingStatements.length, (i) => ({
        x: (i - currentIndex) * width,
        display: 'block',
    }));

    // Update springs when currentIndex or width changes
    useEffect(() => {
        api.start((i) => ({
            x: (i - currentIndex) * width,
            display: 'block',
        }));
    }, [currentIndex, api, width]);

    // Gesture handling for mobile
    const bind = useDrag(
        ({
            active,
            movement: [mx],
            direction: [dx],
            distance: [distX],
            velocity: [velocityX],
        }) => {
            if (isDesktop) return; // Disable swiping on desktop

            // Define thresholds
            const distanceThreshold = width / 4; // 25% of screen width
            const velocityThreshold = 0.3; // Minimum velocity

            // Determine if swipe should trigger
            const trigger = distX > distanceThreshold || velocityX > velocityThreshold;

            if (active) {
                // Update springs during drag
                api.start((i) => {
                    if (i < currentIndex - 1 || i > currentIndex + 1) {
                        return { display: 'none' };
                    }
                    const x = (i - currentIndex) * width + mx;
                    return { x, display: 'block' };
                });
            } else {
                if (trigger) {
                    if (dx > 0 && currentIndex > 0) {
                        // Swipe right to previous
                        updateCurrentIndex(currentIndex - 1);
                    } else if (dx < 0 && currentIndex < copingStatements.length - 1) {
                        // Swipe left to next
                        updateCurrentIndex(currentIndex + 1);
                    }
                }
                // Snap back to position
                api.start((i) => ({
                    x: (i - currentIndex) * width,
                    display: 'block',
                }));
            }
        },
        {
            axis: 'x',
            filterTaps: true,
            swipe: { distance: [50, 50] },
        }
    );

    // Update currentIndex and tracking variables
    const updateCurrentIndex = (newIndex) => {
        if (newIndex !== currentIndex) {
            setCurrentIndex(newIndex);

            // Update tracking variables
            if (!viewedStatementsRef.current.has(newIndex)) {
                viewedStatementsRef.current.add(newIndex);
            }
            if (!choseDifferentRef.current && viewedStatementsRef.current.size > 1) {
                choseDifferentRef.current = true;
            }
        }
    };

    const handleContinue = () => {
        sendAnalyticsEvent(); // Send the event
        copingStatementStore.goToNextStep();
    };

    const handleEnd = () => {
        sendAnalyticsEvent(); // Send the event

        handleEvent({
            eventName: COMPLETED_COPING_STRATEGY_PREMATURELY,
            isDesktop,
            properties: {
                ending_screen: "repeat_coping_statement_step"
            }
        });

        onEnd();
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            updateCurrentIndex(currentIndex - 1);
        }
    };

    const handleNext = () => {
        if (currentIndex < copingStatements.length - 1) {
            updateCurrentIndex(currentIndex + 1);
        }
    };

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
                overflow: 'hidden',
                position: 'relative',
            }}
            data-qa-id="repeat-coping-statement-step"
        >

            {/* Top bar with End button */}
            <StyledAppBar data-qa-id="header">
                <Button
                    onClick={handleEnd}
                    sx={{
                        fontWeight: 'bold',
                        textTransform: 'none',
                        color: "inherit"
                    }}
                    data-qa-id="end-button"
                >
                    Back to my journal
                </Button>
            </StyledAppBar>


            {/* Content */}
            <Box
                sx={{
                    flexGrow: 12,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                }}
                data-qa-id="content-container"
            >
                {isDesktop && currentIndex > 0 && (
                    <IconButton
                        onClick={handlePrev}
                        sx={{
                            position: 'absolute',
                            left: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 2,
                            color: "inherit",
                        }}
                        data-qa-id="prev-button"
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                )}

                {springs.map(({ x, display }, i) => (
                    <animated.div
                        key={i}
                        {...(!isDesktop && bind())}
                        style={{
                            display,
                            x,
                            touchAction: 'pan-y',
                            position: 'absolute',
                            width: '100%',
                            // add enormous padding for better mobile swipe range
                            padding: isDesktop ? 'inherit' : '80% 0',
                        }}
                    >
                        <Typography
                            variant="h2"
                            align="center"
                            sx={{
                                fontWeight: '600',
                                margin: 4,
                                color: "inherit"
                            }}
                            data-qa-id={`coping-statement-text-${i}`}
                        >
                            {copingStatements[i]}
                        </Typography>
                    </animated.div>
                ))}

                {isDesktop && currentIndex < copingStatements.length - 1 && (
                    <IconButton
                        onClick={handleNext}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 2,
                            color: "inherit",
                        }}
                        data-qa-id="next-button"
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                )}
            </Box>

            {/* Instruction */}
            <Box
                sx={{
                    marginTop: 2,
                    flexGrow: 1,
                    height: pxToRem(48),
                }}
                data-qa-id="instruction-text-container"
            >
                {transitions(
                    (style, item) =>
                        item && (
                            <animated.div style={style}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontSize: isDesktop ? pxToRem(20) : 'inherit',
                                        opacity: 0.8,
                                        color: "inherit"
                                    }}
                                    align="center"
                                    data-qa-id="instruction-text"
                                >
                                    {instructionText}
                                </Typography>
                            </animated.div>
                        )
                )}

                {isDesktop && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: isDesktop ? "50%" : "100%",
                            margin: `${pxToRem(32)} auto`,
                        }}
                        data-qa-id="continue-button-container"
                    >
                        <Button
                            variant="contained"
                            fullWidth={"true"}
                            onClick={handleContinue}
                            sx={{
                                backgroundColor: '#2B3A6C',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: '#2B3A6C',
                                    '&::before': {
                                        backgroundColor: "#170161",

                                    },
                                },
                                '&::before': {
                                    backgroundColor: "#170161",

                                },
                            }}
                            data-qa-id="continue-button"
                        >
                            Continue
                        </Button>
                    </Box>
                )}
            </Box>

            {!isDesktop && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 2,
                    }}
                    data-qa-id="continue-button-container"
                >
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleContinue}
                        sx={{
                            backgroundColor: '#2B3A6C',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: '#2B3A6C',
                                '&::before': {
                                    backgroundColor: "#170161",
                                },
                            },
                            '&::before': {
                                backgroundColor: "#170161",
                            },
                        }}
                        data-qa-id="continue-button"
                    >
                        Continue
                    </Button>
                </Box>
            )}
        </Box>
    );
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    height: HEADER.H_MOBILE,
    zIndex: theme.zIndex.appBar + 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    backgroundColor: "#77ADFF",
    backgroundSize: "20px 20px",
    position: 'fixed',
    paddingRight: pxToRem(16),
    color: "inherit",
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: pxToRem(2),
        backgroundColor: "#5C96EC",
    },
}));

RepeatCopingStatementStep.propTypes = {
    onEnd: PropTypes.func.isRequired,
};

export default RepeatCopingStatementStep;
