import React from 'react';
import { Typography, Box, IconButton, Stack } from '@mui/material';
import { pxToRem } from '../../../theme/typography';

const totalSteps = 6;

const getOnboardingTourSteps = ({ layoutStore, selectedQuestionId = null, isDesktop }) => {
  return [
    // first step - today's prompt
    {
      target: selectedQuestionId ? `.front-question-card-${selectedQuestionId}` : ".front-question-card",
      content: (
        <Box
          sx={{
            backgroundColor: '#FFEFB7',
            p: 0,
            borderRadius: 2,
            color: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            position: 'relative', 
            zIndex: 0,
            padding: `${pxToRem(20)} ${pxToRem(20)} 0px ${pxToRem(20)}`,
          }}
          data-qa-id="first-tour-step"
        >
          <Typography
            variant="body1"
            sx={{ color: 'inherit', textAlign: "left" }}
          >
            Let’s start with the main course! This is where you’ll see today’s prompt.
          </Typography>

          <Stack direction="row" alignItems="end" justifyContent="space-between" data-qa-id={"continue-step-container"} sx={{ marginTop: 2, }}>
            <IconButton
              size="medium"
              variant="outlined"
              sx={{
                borderRadius: pxToRem(8),
                border: `${pxToRem(1)} solid`,
                padding: `${pxToRem(4)} ${pxToRem(8)}`,
                width: "max-content",
                height: pxToRem(28),
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                layoutStore.goToNextStep();
              }}
              data-qa-id={"continue-button"}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", }}
              >
                Continue
              </Typography>
            </IconButton>

            <Typography
              variant="caption"
              sx={{ fontWeight: 600, color: "#8E7C56" }}
            >
              {layoutStore.tourStepIndex + 1}/{totalSteps}
            </Typography>
          </Stack>

        </Box>
      ),
      placement: 'bottom',
      disableBeacon: true,
    },

    // second step - navigate to yesterday's prompt
    {
      ...(isDesktop ? {
        target: ".previous-date-button",
        content: (
          <Box
            sx={{
              backgroundColor: '#FFEFB7',
              p: 0,
              borderRadius: 2,
              color: 'inherit',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              position: 'relative',
              zIndex: 0,
              padding: `${pxToRem(20)} ${pxToRem(20)} 0px ${pxToRem(20)}`,
            }}
            data-qa-id="second-tour-step-desktop"
          >
            <Typography
              variant="body1"
              sx={{ color: 'inherit', textAlign: "left" }}
            >
              Now try clicking the left arrow to see yesterday’s prompt
            </Typography>

            <Stack direction="row" alignItems="end" justifyContent="end" data-qa-id={"continue-step-container"} sx={{ marginTop: 2, }}>
              <Typography
                variant="caption"
                sx={{ fontWeight: 600, color: "#8E7C56" }}
              >
                {layoutStore.tourStepIndex + 1}/{totalSteps}
              </Typography>
            </Stack>
          </Box>
        ),
        placement: 'top',
        disableBeacon: true,
        // Keep overlay visible but non-interactive
        styles: {
          overlay: {
            pointerEvents: 'none', // overlay can't block clicks
          },
        },

      } : {
        target: selectedQuestionId ? `.front-question-card-${selectedQuestionId}` : ".front-question-card",
        content: (
          <Box
            sx={{
              backgroundColor: '#FFEFB7',
              p: 0,
              borderRadius: 2,
              color: 'inherit',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              position: 'relative',
              zIndex: 0,
              padding: `${pxToRem(20)} ${pxToRem(20)} 0px ${pxToRem(20)}`,
            }}
            data-qa-id="second-tour-step-mobile"
          >
            <Typography
              variant="body1"
              sx={{ color: 'inherit', textAlign: "left" }}
            >
              Now try swiping right to see yesterday’s prompt
            </Typography>

            <Stack direction="row" alignItems="end" justifyContent="end" data-qa-id={"continue-step-container"} sx={{ marginTop: 2, }}>

              <Typography
                variant="caption"
                sx={{ fontWeight: 600, color: "#8E7C56" }}
              >
                {layoutStore.tourStepIndex + 1}/{totalSteps}
              </Typography>
            </Stack>
          </Box>
        ),
        placement: 'top',
        disableBeacon: true,
        // Keep overlay visible but non-interactive
        styles: {
          overlay: {
            pointerEvents: 'none', // overlay can't block clicks
          },
        },

      })
    },

    // third step - jump to today's question
    {
      ...(isDesktop ? {
        target: ".jump-to-today-button-desktop",
        content: (
          <Box
            sx={{
              backgroundColor: '#FFEFB7',
              p: 0,
              borderRadius: 2,
              color: 'inherit',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              position: 'relative',
              zIndex: 0,
              padding: `${pxToRem(20)} ${pxToRem(20)} 0px ${pxToRem(20)}`,
            }}
            data-qa-id="third-tour-step-desktop"
          >
            <Typography
              variant="body1"
              sx={{ color: 'inherit', textAlign: "left" }}
            >
              Good work! And you can use the 'Skip' button to skip to today.
            </Typography>

            <Stack direction="row" alignItems="end" justifyContent="end" data-qa-id={"continue-step-container"} sx={{ marginTop: 2, }}>

              <Typography
                variant="caption"
                sx={{ fontWeight: 600, color: "#8E7C56" }}
              >
                {layoutStore.tourStepIndex + 1}/{totalSteps}
              </Typography>
            </Stack>
          </Box>
        ),
        placement: 'top',
        disableBeacon: true,
        // Keep overlay visible but non-interactive
        styles: {
          overlay: {
            pointerEvents: 'none', // overlay can't block clicks
          },
        },

      } : {
        target: ".horizontal-nav-item-container-today",
        content: (
          <Box
            sx={{
              backgroundColor: '#FFEFB7',
              p: 0,
              borderRadius: 2,
              color: 'inherit',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              position: 'relative',
              zIndex: 0,
              padding: `${pxToRem(20)} ${pxToRem(20)} 0px ${pxToRem(20)}`,
            }}
            data-qa-id="third-tour-step-mobile"
          >
            <Typography
              variant="body1"
              sx={{ color: 'inherit', textAlign: "left" }}
            >
              Good work! And you can use the ‘Today’ button to jump back.
            </Typography>

            <Stack direction="row" alignItems="end" justifyContent="end" data-qa-id={"continue-step-container"} sx={{ marginTop: 2, }}>
              <Typography
                variant="caption"
                sx={{ fontWeight: 600, color: "#8E7C56" }}
              >
                {layoutStore.tourStepIndex + 1}/{totalSteps}
              </Typography>
            </Stack>
          </Box>
        ),
        placement: 'bottom',
        disableBeacon: true,
        // Keep overlay visible but non-interactive
        styles: {
          overlay: {
            pointerEvents: 'none', // overlay can't block clicks
          },
        },

      })
    },

    // fourth step - calendar view
    {
      ...(isDesktop ? {
        target: ".open-month-view-desktop-icon",
        content: (
          <Box
            sx={{
              backgroundColor: '#FFEFB7',
              p: 0,
              borderRadius: 2,
              color: 'inherit',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              position: 'relative',
              zIndex: 0,
              padding: `${pxToRem(20)} ${pxToRem(20)} 0px ${pxToRem(20)}`,
            }}
            data-qa-id="fourth-tour-step-desktop"
          >
            <Typography
              variant="body1"
              sx={{ color: 'inherit', textAlign: "left" }}
            >
              Awesome. To get a bird's-eye view of all prompts, use the calendar view.
            </Typography>


            <Stack direction="row" alignItems="end" justifyContent="space-between" data-qa-id={"continue-step-container"} sx={{ marginTop: 2, }}>
              <IconButton
                size="medium"
                variant="outlined"
                sx={{
                  borderRadius: pxToRem(8),
                  border: `${pxToRem(1)} solid`,
                  padding: `${pxToRem(4)} ${pxToRem(8)}`,
                  width: "max-content",
                  height: pxToRem(28),
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  layoutStore.goToNextStep();
                }}
                data-qa-id={"continue-button"}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", }}
                >
                  Continue
                </Typography>
              </IconButton>

              <Typography
                variant="caption"
                sx={{ fontWeight: 600, color: "#8E7C56" }}
              >
                {layoutStore.tourStepIndex + 1}/{totalSteps}
              </Typography>
            </Stack>
          </Box>
        ),
        placement: 'top',
        disableBeacon: true,
      } : {
        target: ".open-month-view-mobile-icon",
        content: (
          <Box
            sx={{
              backgroundColor: '#FFEFB7',
              p: 0,
              borderRadius: 2,
              color: 'inherit',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              position: 'relative',
              zIndex: 0,
              padding: `${pxToRem(20)} ${pxToRem(20)} 0px ${pxToRem(20)}`,
            }}
            data-qa-id="fourth-tour-step-mobile"
          >
            <Typography
              variant="body1"
              sx={{ color: 'inherit', textAlign: "left" }}
            >
              Awesome. To get a bird's-eye view of all prompts, use the calendar view.
            </Typography>

            <Stack direction="row" alignItems="end" justifyContent="space-between" data-qa-id={"continue-step-container"} sx={{ marginTop: 2, }}>
              <IconButton
                size="medium"
                variant="outlined"
                sx={{
                  borderRadius: pxToRem(8),
                  border: `${pxToRem(1)} solid`,
                  padding: `${pxToRem(4)} ${pxToRem(8)}`,
                  width: "max-content",
                  height: pxToRem(28),
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  layoutStore.goToNextStep();
                }}
                data-qa-id={"continue-button"}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", }}
                >
                  Continue
                </Typography>
              </IconButton>

              <Typography
                variant="caption"
                sx={{ fontWeight: 600, color: "#8E7C56" }}
              >
                {layoutStore.tourStepIndex + 1}/{totalSteps}
              </Typography>
            </Stack>

          </Box>
        ),
        placement: 'bottom',
        disableBeacon: true,
      })
    },

    // fifth step - toolbox
    {
      ...(isDesktop ? {
        target: ".toolbox-view-desktop-icon",
        content: (
          <Box
            sx={{
              backgroundColor: '#FFEFB7',
              p: 0,
              borderRadius: 2,
              color: 'inherit',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              position: 'relative',
              zIndex: 0,
              padding: `${pxToRem(20)} ${pxToRem(20)} 0px ${pxToRem(20)}`,
            }}
            data-qa-id="fifth-tour-step-desktop"
          >
            <Typography
              variant="body1"
              sx={{ color: 'inherit', textAlign: "left" }}
            >
              OCD journaling can be tough. Use this toolbox to help resist your OCD compulsions.
            </Typography>


            <Stack direction="row" alignItems="end" justifyContent="space-between" data-qa-id={"continue-step-container"} sx={{ marginTop: 2, }}>
              <IconButton
                size="medium"
                variant="outlined"
                sx={{
                  borderRadius: pxToRem(8),
                  border: `${pxToRem(1)} solid`,
                  padding: `${pxToRem(4)} ${pxToRem(8)}`,
                  width: "max-content",
                  height: pxToRem(28),
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  layoutStore.goToNextStep();
                }}
                data-qa-id={"continue-button"}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", }}
                >
                  Continue
                </Typography>
              </IconButton>
              <Typography
                variant="caption"
                sx={{ fontWeight: 600, color: "#8E7C56" }}
              >
                {layoutStore.tourStepIndex + 1}/{totalSteps}
              </Typography>
            </Stack>
          </Box>
        ),
        placement: 'bottom',
        disableBeacon: true,
      } : {
        target: ".toolbox-view-mobile-icon",
        content: (
          <Box
            sx={{
              backgroundColor: '#FFEFB7',
              p: 0,
              borderRadius: 2,
              color: 'inherit',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              position: 'relative',
              zIndex: 0,
              padding: `${pxToRem(20)} ${pxToRem(20)} 0px ${pxToRem(20)}`,
            }}
            data-qa-id="fifth-tour-step-mobile"
          >
            <Typography
              variant="body1"
              sx={{ color: 'inherit', textAlign: "left" }}
            >
              OCD journaling can be tough. Use this toolbox to help resist your OCD compulsions.
            </Typography>


            <Stack direction="row" alignItems="end" justifyContent="space-between" data-qa-id={"continue-step-container"} sx={{ marginTop: 2, }}>
              <IconButton
                size="medium"
                variant="outlined"
                sx={{
                  borderRadius: pxToRem(8),
                  border: `${pxToRem(1)} solid`,
                  padding: `${pxToRem(4)} ${pxToRem(8)}`,
                  width: "max-content",
                  height: pxToRem(28),
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  layoutStore.goToNextStep();
                }}
                data-qa-id={"continue-button"}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", }}
                >
                  Continue
                </Typography>
              </IconButton>
              <Typography
                variant="caption"
                sx={{ fontWeight: 600, color: "#8E7C56" }}
              >
                {layoutStore.tourStepIndex + 1}/{totalSteps}
              </Typography>
            </Stack>
          </Box>
        ),
        placement: 'bottom',
        disableBeacon: true,
      })
    },

    // sixth step
    {
      ...(isDesktop ? {
        target: ".streak-button",
        content: (
          <Box
            sx={{
              backgroundColor: '#FFEFB7',
              p: 0,
              borderRadius: 2,
              color: 'inherit',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              position: 'relative',
              zIndex: 0,
              padding: `${pxToRem(20)} ${pxToRem(20)} 0px ${pxToRem(20)}`,
            }}
            data-qa-id="sixth-tour-step-desktop"
          >
            <Typography
              variant="body1"
              sx={{ color: 'inherit', textAlign: "left" }}
            >
              And this is where you can see your streak-this is the number of days you’ve consecutively journaled
            </Typography>


            <Stack direction="row" alignItems="end" justifyContent="space-between" data-qa-id={"continue-step-container"} sx={{ marginTop: 2, }}>
              <IconButton
                size="medium"
                variant="outlined"
                sx={{
                  borderRadius: pxToRem(8),
                  border: `${pxToRem(1)} solid`,
                  padding: `${pxToRem(4)} ${pxToRem(8)}`,
                  width: "max-content",
                  height: pxToRem(28),
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  layoutStore.goToNextStep();
                }}
                data-qa-id={"get-to-journaling-button"}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", }}
                >
                  Get to journaling
                </Typography>
              </IconButton>
              <Typography
                variant="caption"
                sx={{ fontWeight: 600, color: "#8E7C56" }}
              >
                {layoutStore.tourStepIndex + 1}/{totalSteps}
              </Typography>
            </Stack>
          </Box>
        ),
        placement: 'top',
        disableBeacon: true,
      } : {
        target: ".streak-button",
        content: (
          <Box
            sx={{
              backgroundColor: '#FFEFB7',
              p: 0,
              borderRadius: 2,
              color: 'inherit',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              position: 'relative',
              zIndex: 0,
              padding: `${pxToRem(20)} ${pxToRem(20)} 0px ${pxToRem(20)}`,
            }}
            data-qa-id="fifth-tour-step-mobile"
          >
            <Typography
              variant="body1"
              sx={{ color: 'inherit', textAlign: "left" }}
            >
              And this is where you can see your streak-this is the number of days you’ve consecutively journaled
            </Typography>


            <Stack direction="row" alignItems="end" justifyContent="space-between" data-qa-id={"continue-step-container"} sx={{ marginTop: 2, }}>
              <IconButton
                size="medium"
                variant="outlined"
                sx={{
                  borderRadius: pxToRem(8),
                  border: `${pxToRem(1)} solid`,
                  padding: `${pxToRem(4)} ${pxToRem(8)}`,
                  width: "max-content",
                  height: pxToRem(28),
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  layoutStore.goToNextStep();
                }}
                data-qa-id={"get-to-journaling-button"}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", }}
                >
                  Get to journaling
                </Typography>
              </IconButton>
              <Typography
                variant="caption"
                sx={{ fontWeight: 600, color: "#8E7C56" }}
              >
                {layoutStore.tourStepIndex + 1}/{totalSteps}
              </Typography>
            </Stack>
          </Box>
        ),
        placement: 'bottom',
        disableBeacon: true,
      })
    },

  ]
};

export default getOnboardingTourSteps;