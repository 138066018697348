import React from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, Button, AppBar } from '@mui/material';
import { useStoresContext } from '../../../stores/useStoresContext';
import PropTypes from 'prop-types';
import useResponsive from '../../../hooks/useResponsive';
import { COPING_STATEMENT_SURVEY, REPEAT_COPING_STATEMENT_STEP } from '../../../constants/coping-strategies/coping-statements';
import { COPING_STRATEGY_TYPES } from '../../../constants/coping-strategy-types';
import apiLayer from '../../../services/api-layer/ApiLayer';
import { handleError } from '../../../utils/handleError';
import { handleEvent } from '../../../utils/handleEvent';
import { COMPLETED_COPING_STRATEGY } from '../../../constants/analytics-events';
import { pxToRem } from '../../../theme/typography';
import { HEADER } from '../../../config-global';
import { styled } from '@mui/material/styles';

const filename = "src/components/coping-statement-modal/coping-statement-steps/DoneOrRepeatStep.js";

const DoneOrRepeatStep = observer(({ onEnd }) => {
    const { copingStatementStore } = useStoresContext();

    const isDesktop = useResponsive('up', 'lg');

    const handleContinueWithCopingStatement = () => {
        copingStatementStore.goToStep(REPEAT_COPING_STATEMENT_STEP);
    };

    const handleFinish = async () => {
        try {
            const strategy_type = COPING_STRATEGY_TYPES.COPING_STATEMENT;

            const res = await apiLayer.checkCopingStrategySurveyCompletion({ strategy_type });

            if (res.data.hasCompleted) {
                onEnd();
            } else {
                copingStatementStore.goToStep(COPING_STATEMENT_SURVEY);
            }
        } catch (err) {
            handleError({
                error: err,
                message: `Error checking coping strategy survey completion.`,
                filename,
                functionName: 'handleFinish',
            });

            onEnd();
        } finally {
            // in any case, user finished 1 cycle of coping strategy here
            handleEvent({
                eventName: COMPLETED_COPING_STRATEGY,
                isDesktop,
            });
        }
    };

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
            }}
            data-qa-id="deep-breath-step"
        >

            {/* Top bar with End button */}
            <StyledAppBar data-qa-id="header">
                <Button
                    onClick={handleFinish}
                    sx={{
                        color: 'inherit',
                        fontWeight: 'bold',
                        textTransform: 'none',
                    }}
                    data-qa-id="end-button"
                >
                    Back to my journal
                </Button>
            </StyledAppBar>

            {/* Content */}
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                }}
                data-qa-id="content-container"
            >
                <Typography
                    variant={isDesktop ? "h3" : "h4"}
                    align="left"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: 2,
                        color: "inherit"
                    }}
                    data-qa-id="instruction-text-first"
                >
                    We know this may not have eased your distress, and that’s okay. The goal isn’t to feel a certain way—it’s to resist your compulsions.
                </Typography>

                {/* Add more space between the sentence completion and examples */}
                <Box sx={{ height: 16 }} /> {/* Adds 16px of space */}

                <Typography
                    variant={isDesktop ? "h3" : "h4"}
                    align="left"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: 2,
                        color: "inherit"
                    }}
                    data-qa-id="instruction-text-second"
                >
                    Use the coping statements as needed to keep resisting.
                </Typography>

            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: isDesktop ? '50%' : '100%',
                    }}
                    data-qa-id="options-container"
                >
                    {/* Continue Button */}
                    <Button
                        variant="contained"
                        onClick={handleFinish}
                        fullWidth={true}
                        sx={{
                            backgroundColor: '#2B3A6C',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            marginTop: 2,
                            '&:hover': {
                                backgroundColor: '#2B3A6C',
                                '&::before': {
                                    backgroundColor: "#170161",
                                },
                            },
                            '&::before': {
                                backgroundColor: "#170161",
                            },
                        }}
                        data-qa-id="continue-button"
                    >
                        I'm done
                    </Button>

                    {/* Play Again Text Button */}
                    <Button
                        variant="text"
                        onClick={handleContinueWithCopingStatement}
                        sx={{
                            marginTop: 2,
                            color: 'inherit',
                            fontWeight: 'bold',
                            textTransform: 'none',
                        }}
                        data-qa-id="back-to-coping-statement-button"
                    >
                        Back to coping statement
                    </Button>
                </Box>
            </Box>
        </Box >
    );
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    height: HEADER.H_MOBILE,
    zIndex: theme.zIndex.appBar + 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    backgroundColor: "#77ADFF",
    backgroundSize: "20px 20px",
    position: 'fixed',
    paddingRight: pxToRem(16),
    color: "inherit",
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: pxToRem(2),
        backgroundColor: "#5C96EC",
    },
}));

DoneOrRepeatStep.propTypes = {
    onEnd: PropTypes.func.isRequired,
};

export default DoneOrRepeatStep;
